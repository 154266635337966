const ACTUALIZATION = 'Actualization';
const ADD_ASSIGNMENT = 'AddAssignment';
const ADD_BANK_CARDS = 'AddBankCards';
const ADD_BLOCK = 'AddBlock';
const ADD_CHAT_GROUP_USERS = 'AddChatGroupUsers';
const ADD_CLIENT_EMAIL = 'AddClientEmail';
const ADD_CLUB_CARD = 'AddClubCard';
const ADD_EXPENSE = 'AddExpense';
const ADD_EXPENSE_LATENESS = 'AddExpenseLateness';
const ADD_FEE = 'AddFee';
const ADD_HARVEST = 'AddHarvest';
const ADD_HOTLINE_CALLS_REPORT = 'AddHotlineCallsReport';
const ADD_INSURANCE = 'AddInsurance';
const ADD_INSURANCE_TYPE = 'AddInsuranceType';
const ADD_PRAVO_TYPE = 'AddPravoType';
const ADD_MFO = 'AddMfo';
const ADD_PARTNER = 'AddPartner';
const ADD_PARTNERS_HOTLINE = 'AddPartnersHotline';
const ADD_PROMOCODE = 'AddPromocode';
const ADD_SUGAR = 'AddSugar';
const ADD_TIPS = 'AddTips';
const ADD_TO_CLIENT_BALANCE = 'AddToClientBalance';
const ADD_TWEET = 'AddTweet';
const ADD_WEBINAR = 'AddWebinar';
const APPROVE_DELETE_ACCOUNT = 'ApproveDeleteAccount';
const ARCHIVE_CALENDAR = 'ArchiveCalendar';
const ARCHIVE_CONTRACT = 'ArchiveContract';
const ASSIGNMENT_DOCUMENTS = 'AssignmentDocuments';
const ASSIGNMENT_MARK_AS_INCORRECT = 'AssignmentMarkAsIncorrect';
const ASSIGNMENT_REPORT = 'assignmentReport';
const ASSIGNMENTS_INFO_LIST = 'AssignmentsInfoList';
const AUTOPAY_INFO_LIST = 'AutopayInfoList';
const BALANCE_MONTHLY = 'BalanceMonthly';
const BELL_TO_REACH = 'BellToReach';
const BUTTON_CONTAINER = 'ButtonContainer';
const BUY_WEBINAR = 'BuyWebinarModal';
const BOOKING_FEE = 'BookingFee';
const CHAT_FOR_CONTRACT = 'ChatForContract';
const CHAT_FOR_DOZHIMS_AI = 'ChatForDozhimsAI';
const CHAT_FOR_HOTLINE = 'ChatForHotline';
const CHAT_FOR_TENDER = 'ChatForTender';
const CHAT_GROUP_INFO = 'ChatGroupInfo';
const CHAT_WITH_ACCOUNTANT = 'ChatWithAccountant';
const CHECK_SUGAR = 'CheckSugar';
const CLIENT_BALANCE_TRANSACTION = 'ClientBalanceTransaction';
const CLIENT_INFO = 'ClientInfo';
const CLUB_CARD_DECREMENT = 'ClubCardDecrement';
const CLUB_CARD_GIFT = 'ClubCardGift';
const CONFIRM_ADD_CONTRACT = 'ConfirmAddContract';
const CONFIRM_EMAIL_ANONIM = 'ConfirmEmailAnonim';
const CONFLICT_COMPLETE = 'ConflictComplete';
const CONTRACT_LIST_FILES = 'ContractListFiles';
const CONTRACT_PAYROLL_PERCENTS = 'ContractPayrollPercents';
const CONTRACT_TASKS = 'ContractTasks';
const CONTRACT_TASKS_CRM = 'ContractTasksCRM';
const CONTRACTS_CHATS_FILTER = 'ContractChatsFilter';
const CORRESPONDENCE_ADD_DOCUMENT = 'CorrespondenceAddDocument';
const CORRESPONDENCE_MODAL_FOR_THE_CONTRACT = 'CorrespondenceModalForTheContract';
const COURT_PRACTICE = 'CourtPractice';
const CREATE_NEW_GROUP_CHAT = 'CreateNewGroupChat';
const DISMISSAL_EMPLOYEE = 'DismissalEmployee';
const DOCUMENT_TEMPLATES = 'DocumentTemplates';
const EXPORT_ACTIVITY = 'ExportActivity';
const EXPORT_ACTUALIZATIONS = 'ExportActualizations';
const EXPORT_ALL_HOTLINE = 'ExportAllHotline';
const EXPORT_ARCHIVAL_QUESTIONNAIRES = 'ExportArchivalQuestionnaires';
const EXPORT_ARCHIVE_CONTRACTS = 'ExportArchiveContracts';
const EXPORT_ASSIGNMENTS = 'ExportAssignments';
const EXPORT_CLIENTS_COSTS = 'ExportClientsCosts';
const EXPORT_CONTRACTS_CLIENTS = 'ExportContracts';
const EXPORT_CORRESPONDENCE = 'ExportCorrespondence';
const EXPORT_DISABLED_PEOPLE = 'ExportDisabledPeople';
const EXPORT_HOTLINE_CALLS = 'ExportHotlineCalls';
const EXPORT_HOTLINE_QUESTIONNAIRES = 'ExportHotlineQuestionnaires';
const EXPORT_INCOMES = 'ExportIncomes';
const EXPORT_LIBRARY = 'ExportLibrary';
const EXPORT_LOAD = 'ExportLoad';
const EXPORT_MONTHLY_PAYMENT = 'ExportMonthlyPayment';
const EXPORT_PAYMENT_QUICK_CHAT = 'ExportPaymentQuickChat';
const EXPORT_PAYMENTS = 'ExportPayments';
const EXPORT_QUICK_CHATS = 'ExportQuickChats';
const EXPORT_RATING_LAWYERS = 'ExportRatingLawyers';
const EXPORT_SUGGESTION_AND_COMPLAINTS = 'ExportSuggestionsAndComplaints';
const FEEDBACK_BONUS_MODAL = 'FeedbackBonusModal';
const FILIAL_WORKERS = 'FilialWorkers';
const FIRST_HOTLINE_BONUS = 'FirstHotlineBonus';
const FIRST_LOGIN_AUTO_PAY = 'FirstLoginAutoPay';
const FIRST_LOGIN_BONUS = 'FirstLoginBonus';
const FIRST_LOGIN_OF_THE_CLIENT = 'FirstLoginOfTheClient';
const FUNCTIONS_NOT_USED = 'FunctionsNotUsed';
const HARVEST_INFO_LIST = 'HarvestInfoList';
const HISTORY_HOTLINES = 'HistoryHotlines';
const HOTLINE_CALLS_FILTER = 'HotlineCallsFilter';
const HOTLINE_MEET_MODAL = 'HotlineMeet';
const HOTLINE_QUE = 'HotlineQue';
const HOTLINE_SUBMIT = 'HotlineSubmit';
const INCOMES = 'Incomes';
const INFO_EXPENSE = 'InfoExpense';
const INFO_INSURANCE = 'InfoInsurance';
const LEAVE_SUGGESTION_MODAL = 'LeaveSuggestionModal';
const LIBRARY_ADD_DOCUMENT = 'LibraryAddDocument';
const LIBRARY_MINI_LIST = 'LibraryMiniList';
const MANAGE_TIME_RESPONSIBILITIES = 'ManageTimeResponsibilities';
const MONTHLY_PAYMENT_CONTRACT = 'MonthlyPaymentContract';
const MOVE_TO_ARCHIVE = 'MoveToArchive';
const NEW_CLIENT = 'NewClient';
const NEW_CONTRACT = 'NewContract';
const NEW_TRANSACTION = 'NewClientTransaction';
const NOTIFICATION = 'Notification';
const PAYMENT_FRAME = 'PaymentFrame';
const PAYMENT_MANAGEMENT = 'PaymentManagement';
const PERSONAL_CONSULTANT_CHAT = 'PersonalConsultantChat';
const PSYCHOLOGIST_HOTLINE_EDIT = 'PsychologistHotlineEdit';
const PSYCHOLOGIST_HOTLINE_REMOVE = 'PsychologistHotlineRemove';
const PSYCHOLOGIST_HOTLINE_REPORTING = 'PsychologistHotlineReporting';
const PSYCHOLOGIST_HOTLINE_SUBMIT = 'PsychologistHotlineSubmit';
const PUBLISH_FEEDBACK = 'PublishFeedback';
const QUESTIONNAIRE_ARCHIVE = 'QuestionnaireArchive';
const QUESTIONNAIRE_ARCHIVE_ADVERTISING = 'QuestionnaireArchiveAdvertising';
const QUESTIONNAIRE_HOTLINE = 'QuestionnaireHotline';
const QUESTIONNAIRE_HOTLINE_ADVERTISING = 'QuestionnaireHotlineAdvertising';
const QUESTIONNAIRE_HOTLINE_CERTIFICATE = 'QuestionnaireHotlineCertificate';
const QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT = 'QuestionnaireInterimServiceQualityAssessment';
const RESPOND_TO_TENDER = 'RespondToTender';
const SEARCH_CLIENT = 'SearchClient';
const SEND_CONTRACT_DATA_MFO = 'SendContractDataMFO';
const SPHERE_EDIT_CATEGORY = 'SphereEditCategory';
const SPHERE_EDIT_SERVICE_ITEM = 'SphereEditServiceItem';
const TASK_INFO = 'TaskInfo';
const TASKS_FILTER = 'TasksFilter';
const USER_COURT_PRACTICE = 'UserCourtPractice';
const VOTING_FOR_ARCHIVING = 'VotingForArchiving';
const WALLET_ASSIGNMENT = 'walletAssignment';
const CONTRACT_CREATE_CALL = 'ContractCreateCall';
const EXPORT_CONTRACTS_POINTS_PLAN_STAT = 'ExportContractsPointsPlanStat';
const FILE_PREVIEW = 'FilePreview';
const CASINO_MODAL = 'CasinoModal';
const TRANSFER_TALANTS_MODAL = 'TransferTalantsModal';
const TRANSFER_SUCCESS_MODAL = 'TransferSuccessModal';
const PAY_EXPENSE_WITH_TALANTS = 'PayExpenseWithTalants';
const ADD_SHOP_ITEM = 'AddShopItem';
const TALANTS_GIFT = 'TalantsGift';

module.exports = {
  ACTUALIZATION,
  ADD_ASSIGNMENT,
  ADD_BANK_CARDS,
  ADD_BLOCK,
  ADD_CHAT_GROUP_USERS,
  ADD_CLIENT_EMAIL,
  ADD_CLUB_CARD,
  ADD_EXPENSE_LATENESS,
  ADD_EXPENSE,
  ADD_FEE,
  ADD_HARVEST,
  ADD_HOTLINE_CALLS_REPORT,
  ADD_INSURANCE_TYPE,
  ADD_PRAVO_TYPE,
  ADD_INSURANCE,
  ADD_MFO,
  ADD_PARTNER,
  ADD_PARTNERS_HOTLINE,
  ADD_PROMOCODE,
  ADD_SUGAR,
  ADD_TIPS,
  ADD_TO_CLIENT_BALANCE,
  ADD_TWEET,
  ADD_WEBINAR,
  APPROVE_DELETE_ACCOUNT,
  ARCHIVE_CALENDAR,
  ARCHIVE_CONTRACT,
  ASSIGNMENT_DOCUMENTS,
  ASSIGNMENT_MARK_AS_INCORRECT,
  ASSIGNMENT_REPORT,
  ASSIGNMENTS_INFO_LIST,
  AUTOPAY_INFO_LIST,
  BALANCE_MONTHLY,
  BELL_TO_REACH,
  BUTTON_CONTAINER,
  BUY_WEBINAR,
  BOOKING_FEE,
  CHAT_FOR_CONTRACT,
  CHAT_FOR_DOZHIMS_AI,
  CHAT_FOR_HOTLINE,
  CHAT_FOR_TENDER,
  CHAT_GROUP_INFO,
  CHAT_WITH_ACCOUNTANT,
  CHECK_SUGAR,
  CLIENT_BALANCE_TRANSACTION,
  CLIENT_INFO,
  CLUB_CARD_DECREMENT,
  CLUB_CARD_GIFT,
  CONFIRM_ADD_CONTRACT,
  CONFIRM_EMAIL_ANONIM,
  CONFLICT_COMPLETE,
  CONTRACT_LIST_FILES,
  CONTRACT_PAYROLL_PERCENTS,
  CONTRACT_TASKS_CRM,
  CONTRACT_TASKS,
  CONTRACTS_CHATS_FILTER,
  CORRESPONDENCE_ADD_DOCUMENT,
  CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
  COURT_PRACTICE,
  CREATE_NEW_GROUP_CHAT,
  DISMISSAL_EMPLOYEE,
  DOCUMENT_TEMPLATES,
  EXPORT_ACTIVITY,
  EXPORT_ACTUALIZATIONS,
  EXPORT_ALL_HOTLINE,
  EXPORT_ARCHIVAL_QUESTIONNAIRES,
  EXPORT_ARCHIVE_CONTRACTS,
  EXPORT_ASSIGNMENTS,
  EXPORT_CLIENTS_COSTS,
  EXPORT_CONTRACTS_CLIENTS,
  EXPORT_CORRESPONDENCE,
  EXPORT_DISABLED_PEOPLE,
  EXPORT_HOTLINE_CALLS,
  EXPORT_HOTLINE_QUESTIONNAIRES,
  EXPORT_INCOMES,
  EXPORT_LIBRARY,
  EXPORT_LOAD,
  EXPORT_MONTHLY_PAYMENT,
  EXPORT_PAYMENT_QUICK_CHAT,
  EXPORT_PAYMENTS,
  EXPORT_QUICK_CHATS,
  EXPORT_RATING_LAWYERS,
  EXPORT_SUGGESTION_AND_COMPLAINTS,
  FEEDBACK_BONUS_MODAL,
  FILIAL_WORKERS,
  FIRST_HOTLINE_BONUS,
  FIRST_LOGIN_AUTO_PAY,
  FIRST_LOGIN_BONUS,
  FIRST_LOGIN_OF_THE_CLIENT,
  FUNCTIONS_NOT_USED,
  HARVEST_INFO_LIST,
  HISTORY_HOTLINES,
  HOTLINE_CALLS_FILTER,
  HOTLINE_MEET_MODAL,
  HOTLINE_QUE,
  HOTLINE_SUBMIT,
  INCOMES,
  INFO_EXPENSE,
  INFO_INSURANCE,
  LEAVE_SUGGESTION_MODAL,
  LIBRARY_ADD_DOCUMENT,
  LIBRARY_MINI_LIST,
  MANAGE_TIME_RESPONSIBILITIES,
  MONTHLY_PAYMENT_CONTRACT,
  MOVE_TO_ARCHIVE,
  NEW_CLIENT,
  NEW_CONTRACT,
  NEW_TRANSACTION,
  NOTIFICATION,
  PAYMENT_FRAME,
  PAYMENT_MANAGEMENT,
  PERSONAL_CONSULTANT_CHAT,
  PSYCHOLOGIST_HOTLINE_EDIT,
  PSYCHOLOGIST_HOTLINE_REMOVE,
  PSYCHOLOGIST_HOTLINE_REPORTING,
  PSYCHOLOGIST_HOTLINE_SUBMIT,
  PUBLISH_FEEDBACK,
  QUESTIONNAIRE_ARCHIVE_ADVERTISING,
  QUESTIONNAIRE_ARCHIVE,
  QUESTIONNAIRE_HOTLINE_ADVERTISING,
  QUESTIONNAIRE_HOTLINE_CERTIFICATE,
  QUESTIONNAIRE_HOTLINE,
  QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT,
  RESPOND_TO_TENDER,
  SEARCH_CLIENT,
  SEND_CONTRACT_DATA_MFO,
  SPHERE_EDIT_CATEGORY,
  SPHERE_EDIT_SERVICE_ITEM,
  TASK_INFO,
  TASKS_FILTER,
  USER_COURT_PRACTICE,
  VOTING_FOR_ARCHIVING,
  WALLET_ASSIGNMENT,
  ADD_PROMOCODE,
  FIRST_LOGIN_BONUS,
  FIRST_HOTLINE_BONUS,
  ADD_PARTNER,
  ADD_PARTNERS_HOTLINE,
  CONTRACT_CREATE_CALL,
  EXPORT_CONTRACTS_POINTS_PLAN_STAT,
  FILE_PREVIEW,
  CASINO_MODAL,
  TRANSFER_TALANTS_MODAL,
  TRANSFER_SUCCESS_MODAL,
  PAY_EXPENSE_WITH_TALANTS,
  ADD_SHOP_ITEM,
  TALANTS_GIFT,
};
