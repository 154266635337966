const { QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT } = require('../modals');
const { translation } = require('../translation');
const CLIENT_DOMAIN = 'client.zahist.ua';
const CLIENT_LINK = `https://${CLIENT_DOMAIN}`;

const APP_DOMAIN = 'app.zahist.ua';
const APP_LINK = `https://${APP_DOMAIN}`;

const WEB_SITE_DOMAIN = 'zahist.ua';
const WEB_SITE_LINK = `https://${WEB_SITE_DOMAIN}`;

const OLD_WEB_SITE_DOMAIN = 'zahist.in.ua';
const OLD_WEB_SITE_LINK = `https://${OLD_WEB_SITE_DOMAIN}`;

const WEB_SITE_INT_DOMAIN = 'zahist.lawyer';
const WEB_SITE_INT_LINK = `https://${WEB_SITE_INT_DOMAIN}`;

const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.zahist.client';
const APP_LINK_IOS = 'https://apps.apple.com/us/app/єАдвокат/id6463437697';

const GET_IP_API = 'https://api64.ipify.org/?format=json';
const GEO_API = 'http://www.geoplugin.net/json.gp';

// CRM ROUTES
const ROUTES = {
  HOME: '/',
  AUTH: '/auth',
  HOME_DASHBOARD: '/homeDashboard',
  PROFILE: '/profile',
  HOTLINE: '/hotline',
  PSYCHOLOGIST_HOTLINE: '/PsychologistHotline',
  LIST_PSYCHOLOGIST_HOTLINE: '/ListPsychologistHotline',
  HOTLINE_SAVED: '/hotlineSaved',
  HOTLINE_ID: '/hotline/:_id',
  HOTLINE_REACH: '/hotlineReach',
  FAILURE_TO_PAY: '/failureToPay',
  LIST: '/list',
  HACKWORKS: '/hackworks',
  // GDUN: '/gdun',
  // GDUN_STAT: '/gdunStat',
  SEARCH: '/search',
  PERSONNEL_DIRECTORY: '/personnelDirectory',
  CLUB_CARDS: '/clubСards',
  CLIENTS: '/clients',
  PARTNERS: '/partners',
  CLIENT_INFO: '/clientInfo/:id',
  CLIENT_INFO_: '/clientInfo/',
  CONTRACTS: '/contracts',
  AUTOPAY_PAGE: '/autopay',
  HELPER: '/helper',
  HELPER_CALC: '/helperCalc',
  JOURNAL: '/journal',
  HOTLINES_BY_CLIENTS: '/hotlinesByClients',
  HOTLINE_UPDATE: '/hotlineUpdate',
  HARVEST: '/harvest',
  ASSIGNMENT: '/assignment',
  ACTIVITY: '/activity',
  LIBRARY: '/library',
  CORRESPONDENCE_PAGE: '/correspondencePage',
  BLOCKED: '/blocked',
  RATING: '/rating',
  PRAVO: '/pravo',
  PRAVO_RATING: '/pravoRating',
  FILII: '/filii',
  FEE: '/fee',
  CHAT: '/chat',
  CLIENTS_FEEDBACK: '/clientsFeedback',
  COMPLAINTS: '/complaints',
  FUNCTION_SURVEY: '/functionSurvey',
  EXPENSE: '/expenses',
  EXPENSE_ID: '/expenses/:_id',
  TASKS: '/tasks',
  SUGAR: '/sugar',
  PUSH_NOTIFICATION: '/pushNotification',
  TENDERS: '/tenders',
  INSURANCES: '/insurances',
  ACCESS_LIST: '/accessList',
  ACCESS_CONTRACTS: '/accessContracts',
  SUGGESTIONS: '/suggestions',
  CONFLICTS: '/conflicts',
  CONFLICT_CHAT: '/conflicts/:id',
  TWITTER: '/twitter',
  TWITTER_ID: '/twitter/:id',
  RED_BUTTON_CHAT: '/redButtonChat',
  DOCUMENTS: '/documents',
  WEBINARS: '/webinars',
  CLIENTS_QUICK_CHATS: '/clientsQuickChats',
  CLIENTS_DOZHIMS_AI_CHATS: '/clientsDozhimsAIChats',
  DOZHIMS_ANK: '/dozhimsAnk',
  DOZHIMS_ZSU: '/dozhimsZSU',

  //admin part
  USERS: '/users',
  USER_ADD: '/userAdd',
  USER_EDIT_ID: '/userEdit/:_id',
  CABINETS: '/cabinets',
  SENDER: '/sender',
  BANK_CARDS: '/bankCards',
  STATISTIC: '/statistic',
  STATISTIC_MEET_CANCELATION_REASONS: '/statistic/meetCancelationReasons',
  STATISTIC_CLIENTS_DEMOGRAPHY: '/statistic/clientsDemography',
  STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP: '/statistic/filActiveClientsWithApp',
  STATISTIC_FIL_ACTIVE_CONTRACTS: '/statistic/filActiveContracts',
  STATISTIC_FIL_ARCHIVE_CANDY: '/statistic/filArchiveCandy',
  STATISTIC_FIL_BALANCE: '/statistic/filBalance',
  STATISTIC_CLIENT_PAGES: '/statistic/clientPages',
  STATISTIC_USERS_LOGS: '/statistic/usersLogs',
  STATISTIC_SERVER_LOGS: '/statistic/serverLogs',
  WEBSITE_NEWS: '/websiteNews',
  PUBLISH_WEBSITE: '/publishWebsite',
  DEVELOPMENT: '/development',
  MFO: '/mfo',
  LATENESS: '/lateness',
  WALLET: '/wallet',
  WALLET_MONTH: '/walletMonth',
  WALLET_MONTH_FILIALS: '/walletMonthFilials',
  PAYMENT_MANAGEMENT_FILL_CHART: '/statistic/paymentManagementFillChart',
  STATISTIC_PROMOCODES: '/statistic/promocodes',
  FONDY_HOTLINES: '/fondyHotlines',
  INSURANCE_TYPE: '/insuranceType',
  IT_SERVICES: '/itServices',
  DUBLICATES: '/dublicates',
  HOTLINE_CALLS: '/hotlineCalls',
  QUESTIONNAIRES: '/questionnaires',
  SPHERE: '/sphere',
  PROMOCODES: '/promocodes',
  PARTNERS_USERS: '/partnersUsers',
  SETTINGS_WEBSITE: '/settingsWebsite',
  OBZVONI: '/obzvoni',
  OBZVONI_ITEM: '/obzvoni/:fil',
  BALANCE_SHOP: '/balanceShop',
  WINNINGS_HISTORY: '/winningsHistory',
  GEOLOCATION_TEST: '/geolocationTest',
};

const ROUTE_TITLES = {
  [ROUTES.HOME]: 'Головна сторінка',
  [ROUTES.AUTH]: 'Авторизація',
  [ROUTES.HOME_DASHBOARD]: 'Головна сторінка',
  [ROUTES.PROFILE]: 'Профіль',
  [ROUTES.HOTLINE]: 'Запис на консультацію',
  [ROUTES.PSYCHOLOGIST_HOTLINE]: 'Запис до психолога',
  [ROUTES.LIST_PSYCHOLOGIST_HOTLINE]: 'Записи до психолога',
  [ROUTES.HOTLINE_SAVED]: 'Запис збережено',
  [ROUTES.HOTLINE_REACH]: 'Дожими',
  [ROUTES.FAILURE_TO_PAY]: 'Невнесені оплати',
  [ROUTES.LIST]: 'Існуючі записи',
  [ROUTES.HACKWORKS]: 'Халтури',
  [ROUTES.SEARCH]: 'Пошук по номеру телефона',
  [ROUTES.PERSONNEL_DIRECTORY]: 'Довідник',
  [ROUTES.CLUB_CARDS]: 'Клубні карти',
  [ROUTES.CLIENTS]: 'Клієнти',
  [ROUTES.PARTNERS]: 'Партнери',
  [ROUTES.CONTRACTS]: 'Контракти',
  [ROUTES.AUTOPAY_PAGE]: 'Автооплати',
  [ROUTES.HELPER]: 'Помічник',
  [ROUTES.HELPER_CALC]: 'Калькулятор гонорару',
  [ROUTES.JOURNAL]: 'Журнал активності',
  [ROUTES.HOTLINES_BY_CLIENTS]: 'Записи клієнтів',
  [ROUTES.HOTLINE_UPDATE]: 'Оновити запис',
  [ROUTES.HARVEST]: 'Жнива',
  [ROUTES.ASSIGNMENT]: 'Виходи',
  [ROUTES.ACTIVITY]: 'Активність',
  [ROUTES.LIBRARY]: 'Бібліотека',
  [ROUTES.CORRESPONDENCE_PAGE]: 'Кореспонденція',
  [ROUTES.BLOCKED]: 'Заблоковані користувачі',
  [ROUTES.RATING]: 'Рейтинг',
  [ROUTES.PRAVO_RATING]: 'Рейтинг права',
  [ROUTES.FILII]: 'Філії',
  [ROUTES.FEE]: 'Розвідка гонорару',
  [ROUTES.CHAT]: 'Чат',
  [ROUTES.CLIENTS_FEEDBACK]: 'Відгуки клієнтів',
  [ROUTES.COMPLAINTS]: 'Скарги',
  [ROUTES.FUNCTION_SURVEY]: 'Анкетування по функціоналу',
  [ROUTES.TASKS]: 'Завдання',
  [ROUTES.SUGAR]: 'Сахорок',
  [ROUTES.PUSH_NOTIFICATION]: 'Пуш-повідомлення',
  [ROUTES.TENDERS]: 'Тендери',
  [ROUTES.INSURANCES]: 'Страховки',
  [ROUTES.ACCESS_LIST]: 'Доступи',
  [ROUTES.SUGGESTIONS]: 'Пропозиції',
  [ROUTES.CONFLICTS]: 'Конфлікти',
  [ROUTES.TWITTER]: 'Твіттер',
  [ROUTES.RED_BUTTON_CHAT]: 'Термінові чати',
  [ROUTES.DOCUMENTS]: 'Документи',
  [ROUTES.WEBINARS]: 'Вебінари',
  [ROUTES.CLIENTS_QUICK_CHATS]: 'Швидкі питання клієнтів',
  [ROUTES.CLIENTS_DOZHIMS_AI_CHATS]: 'AI дожими клієнтів',
  [ROUTES.DOZHIMS_ANK]: 'Дожими АНК',
  [ROUTES.DOZHIMS_ZSU]: 'Дожими ЗСУХ',
  [ROUTES.USERS]: 'Користувачі',
  [ROUTES.USER_ADD]: 'Додати користувача',
  [ROUTES.CABINETS]: 'Кабінети',
  [ROUTES.SENDER]: 'Конвеєр розсилок',
  [ROUTES.BANK_CARDS]: 'Рахунки та Картки',
  [ROUTES.STATISTIC]: 'Статистика',
  [ROUTES.STATISTIC_MEET_CANCELATION_REASONS]: 'Статистика причин відмов',
  [ROUTES.STATISTIC_CLIENTS_DEMOGRAPHY]: 'Демографія клієнтів',
  [ROUTES.STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP]: 'Активні клієнти з додатком',
  [ROUTES.STATISTIC_FIL_ACTIVE_CONTRACTS]: 'Активні договори',
  [ROUTES.STATISTIC_FIL_ARCHIVE_CANDY]: 'Архів цукерок',
  [ROUTES.STATISTIC_FIL_BALANCE]: 'Баланс філій',
  [ROUTES.STATISTIC_CLIENT_PAGES]: 'Статистика популярності сторінок',
  [ROUTES.STATISTIC_USERS_LOGS]: 'Статистика логів користувачів',
  [ROUTES.STATISTIC_SERVER_LOGS]: 'Статистика логів серверу',
  [ROUTES.WEBSITE_NEWS]: 'Новини сайту',
  [ROUTES.PUBLISH_WEBSITE]: 'Публікація сайту',
  [ROUTES.DEVELOPMENT]: 'Розробка',
  [ROUTES.MFO]: 'МФО',
  [ROUTES.LATENESS]: 'Запізнення',
  [ROUTES.WALLET]: 'Гаманець',
  [ROUTES.WALLET_MONTH]: 'Гаманець за місяць',
  [ROUTES.WALLET_MONTH_FILIALS]: 'Баланс філій',
  [ROUTES.PAYMENT_MANAGEMENT_FILL_CHART]: 'Статистика заповнених договорів',
  [ROUTES.STATISTIC_PROMOCODES]: 'Статистика промокодів',
  [ROUTES.FONDY_HOTLINES]: 'Оплати фонді консультацій',
  [ROUTES.INSURANCE_TYPE]: 'Типи страховок',
  [ROUTES.IT_SERVICES]: 'IT послуши',
  [ROUTES.DUBLICATES]: 'Дублікати',
  [ROUTES.HOTLINE_CALLS]: 'Гаряча лінія дзвінків',
  [ROUTES.QUESTIONNAIRES]: 'Оцінка якось послуг',
  [ROUTES.SPHERE]: '3Д Сфера',
  [ROUTES.PROMOCODES]: 'Промокоди',
  [ROUTES.PARTNERS_USERS]: 'Партнери',
  [ROUTES.SETTINGS_WEBSITE]: 'Налаштування',
  [ROUTES.OBZVONI]: 'Обдзвони',
  [ROUTES.BALANCE_SHOP]: 'Магазин',
  [ROUTES.WINNINGS_HISTORY]: 'Таланти співробітників',
  [ROUTES.GEOLOCATION_TEST]: 'Тест геолокації',
};

// Please, for client pages statistics add additional information for your client routes!!!
const CLIENT_ROUTES = {
  //public client part
  CLIENT_LOGIN: {
    route: '/clientLogin',
    pathname: 'clientLogin',
    name: 'Вхід',
    title: translation.enter,
  },
  CLIENT_REGISTRATION: {
    route: '/clientReg',
    pathname: 'clientReg',
    name: 'Реєстрація',
    title: translation.register,
  },
  CLIENT_CHANGE_PASSWORD: {
    route: '/clientChangePassword',
    pathname: 'clientChangePassword',
    name: 'Зміна паролю',
    title: translation.clientConfirmNPUpdatePBtn,
  },
  CLIENT_CONFIRM_NEW_PASSWORD: {
    route: '/clientConfirmNewPassword/:code/:email',
    pathname: 'clientConfirmNewPassword',
    name: 'Підтвердження нового паролю',
    title: translation.confirmPassword,
  },
  CLIENT_VERIFICATION_PHONE: {
    route: '/clientVerificationPhone',
    pathname: 'clientVerificationPhone',
    name: 'Підтвердження телефону',
    title: translation.regStep2Confirm8,
  },
  CLIENT_POLICY_CONFIRM: {
    route: '/PolicyConfirm',
    pathname: 'PolicyConfirm',
    name: 'Підтвердження політики',
    title: translation.PrivacyPolicyTitle,
  },
  ANONIM_BONUS_HOME: {
    route: '/bonus3000',
    pathname: 'bonus3000',
    name: 'Анонімний бонус',
    title: translation.bonus3000,
  },
  ANONIM_CERTIFICATE_OLD_: {
    route: '/certificate/',
    pathname: 'certificate',
    name: 'Старий анонімний сертифікат',
  },
  ANONIM_CERTIFICATE_: {
    route: '/cert/',
    pathname: 'cert',
    name: 'Анонімний сертифікат',
  },
  HOTLINE_MEET_INFO: {
    route: '/:hash',
    pathname: 'hotlineMeetInfo',
    name: 'Інформація про зустріч',
    title: translation.clientCHotlineInf,
  },
  PAYMENT_SUCCESS: {
    route: '/paymentSuccess',
    pathname: 'paymentSuccess',
    name: 'Успішний платіж',
    title: translation.paymentSuccess,
  },
  PAYMENT_FOR_HOTLINE: {
    route: '/pfh/:hotlineIndex',
    pathname: 'hotlinePayment',
    name: 'Платіж зустрічі',
  },
  LAWYER_CV: {
    route: '/cv/:id',
    pathname: 'cv',
    name: 'Резюме',
  },
  LAWYER_CV_HEADER_TEXT: '/cv/',
  FILII_STATE: {
    route: '/filiiState/:state',
    pathname: 'filiiState',
    name: 'Інфо філії',
    title: translation.branchInfo,
  },
  CLUB_CARD_PAGE: {
    route: '/clubCardPage',
    pathname: 'clubCardPage',
    name: 'Клубна карта сторінка',
    title: translation.clubCard,
  },
  INVITE_LINK: {
    route: '/i/:token',
    pathname: 'i',
    name: 'Посилання запрошення',
  },

  //protected client part

  CLIENT_HOME: {
    route: '/homepage',
    pathname: 'homepage',
    name: 'Головна сторінка',
    title: translation.homePage,
  },
  CLUB_CARD: {
    route: '/clubCard',
    pathname: 'clubCard',
    name: 'Клубна карта',
    title: translation.balance,
  },
  CLIENT_INSURANCE: {
    route: '/insuranceAccount',
    pathname: 'insuranceAccount',
    name: 'страховий рахунок',
    title: translation.insuranceCard,
  },
  CLIENT_HOTLINE: {
    route: '/hotline',
    pathname: 'hotline',
    name: 'Записи зустрічей',
    title: translation.hotline,
  },
  // CLIENT_MY_HOTLINES: {
  //   route: '/myHotlines',
  //   pathname: 'myHotlines',
  //   name: 'Мої зустрічі',
  // },
  INVITE_FRIEND: {
    route: '/inviteFriend',
    pathname: 'inviteFriend',
    name: 'Запросити друга',
    title: translation.partnerCabinet,
  },
  CLIENT_CONTRACTS: {
    route: '/contracts',
    pathname: 'contracts',
    name: 'Договори',
    title: translation.myBusiness,
  },
  CLIENT_CONTRACT_INFO: {
    route: '/contractInfo/:id',
    pathname: 'contractInfo',
    name: 'Інфо договора',
    title: translation.clientCInfTitle,
  },
  CLIENT_CONTRACT_INFO_HEADER_TEXT: '/contractInfo/',
  CLIENT_PROFILE: {
    route: '/profile',
    pathname: 'profile',
    name: 'Профіль',
    title: translation.profile,
  },
  CLIENT_TENDERS: {
    route: '/clientTenders',
    pathname: 'clientTenders',
    name: 'Тендери',
    title: translation.tenders,
  },
  CREATE_TENDER: {
    route: '/createTender',
    pathname: 'createTender',
    name: 'створення тендеру',
    title: translation.createTender,
  },
  CLIENT_TENDER_INFO: {
    route: '/clientTenderInfo/:id',
    pathname: 'clientTenderInfo',
    name: 'Інфо тендеру',
  },
  CLIENT_TENDER_INFO_HEADER_TEXT: '/clientTenderInfo/',
  CLIENT_QUICK_CHAT: {
    route: '/clientQuickChat',
    pathname: 'clientQuickChat',
    name: 'Швидкі питання',
    title: translation.aiAssistantTitle,
  },
  CLIENT_CONTACT_US: {
    route: '/clientContactUs',
    pathname: 'clientContactUs',
    name: 'Контакти',
    title: translation.clientContactUsTitle1,
  },
  CLIENT_BONUS: {
    route: '/bonusAccount',
    pathname: 'bonusAccount',
    name: 'Бонуси',
    title: translation.clientHomeBalanceNew,
  },
  RED_BUTTON: {
    route: '/redButton',
    pathname: 'redButton',
    name: 'Червона кнопка',
    title: translation.redButton,
  },
  CLIENT_CHATS: {
    route: '/clientChats',
    pathname: 'clientChats',
    name: 'Чат',
    title: translation.clientChats,
  },
  AUTOPAY_PAGE: {
    route: '/autopay',
    pathname: 'autopay',
    name: 'Автооплата',
    title: translation.autoPay,
  },
  CLIENTS_FEEDBACK: {
    route: '/clientsFeedback',
    pathname: 'clientsFeedback',
    name: 'Фідбек клієнта',
    title: translation.reviews,
  },
  REGISTER: {
    route: '/register',
    pathname: 'register',
    name: 'Реєстр',
    title: translation.registerPage,
  },
  REGISTER_LIST: {
    route: '/registerList',
    pathname: 'registerList',
    name: 'Реєстр список',
    title: translation.register,
  },
  REGISTER_SEARCH: {
    route: '/registerSearch',
    pathname: 'registerSearch',
    name: 'Реєстр пошук',
    title: translation.registerTitles.search,
  },
  REGISTER_SELF_SEARCH: {
    route: '/registerSelfSearch',
    pathname: 'registerSelfSearch',
    name: 'Реєстр пошук себе',
    title: translation.registerTitles.findMe,
  },
  WEBINAR_ID: {
    route: '/webinar/:id',
    pathname: 'webinar',
    name: 'Вебінар',
    title: translation.webinar,
  },
  HOTLINE: {
    route: '/hotline',
    pathname: 'hotline',
    name: 'Запис на консультацію',
    title: translation.hotline,
  },
  PARTNER_HOTLINE: {
    route: '/partnerHotlines',
    pathname: 'partnerHotlines',
    name: 'Партнерські консультації',
    title: translation.partnerHotline,
  },
  NOT_FOUND: {
    route: '/',
    pathname: 'notFound',
    name: 'Не знайдено',
  },

  // PUSH MODALS
  PUSH_MODALS: {
    INTERIM_SERVICE_QUALITY_ASSESSMENT: {
      route: '/pushModals/interimServiceQualityAssessment',
      pathname: 'pushModals/interimServiceQualityAssessment',
      modal: QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT,
      name: 'Проміжкова оцінка якості послуг',
    },
  },
};

const OPEN_ROUTES = [
  ROUTES.AUTH,
  CLIENT_ROUTES.HOTLINE_MEET_INFO.route,
  CLIENT_ROUTES.ANONIM_BONUS_HOME.route,
  CLIENT_ROUTES.CLIENT_LOGIN.route,
  CLIENT_ROUTES.ANONIM_CERTIFICATE_.route,
  CLIENT_ROUTES.ANONIM_CERTIFICATE_OLD_.route,
  CLIENT_ROUTES.CLIENT_REGISTRATION.route,
  CLIENT_ROUTES.CLIENT_CHANGE_PASSWORD.route,
  CLIENT_ROUTES.CLIENT_CONFIRM_NEW_PASSWORD.route,
  CLIENT_ROUTES.PAYMENT_SUCCESS.route,
  CLIENT_ROUTES.PAYMENT_FOR_HOTLINE.route,
];

const ADMIN_ROUTES = [ROUTES.FILII, ROUTES.COMPLAINTS];

module.exports = {
  APP_DOMAIN,
  APP_LINK,
  CLIENT_DOMAIN,
  CLIENT_LINK,
  WEB_SITE_DOMAIN,
  WEB_SITE_LINK,
  OLD_WEB_SITE_DOMAIN,
  OLD_WEB_SITE_LINK,
  ROUTES,
  OPEN_ROUTES,
  ADMIN_ROUTES,
  APP_LINK_ANDROID,
  APP_LINK_IOS,
  GET_IP_API,
  GEO_API,
  CLIENT_ROUTES,
  WEB_SITE_INT_LINK,
  ROUTE_TITLES,
};
