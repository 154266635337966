import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MOB_RESOLUTION } from 'common_constants/ui';
import { PAYMENT_STATUS, USER_TOKEN_TYPE, WEBVIEW_MESSAGE_TYPES, CHAT_TYPES } from 'common_constants/business';
import * as MODALS from 'common_constants/modals';
import io from 'socket.io-client';

import { setConfetti, setMob } from '../store/uiReducer';
import { request, error, success } from './index';
import {
  setAppPrepared,
  setClientInfo,
  setFiliiList,
  setNewsFromSite,
  setWebinars,
  setTopFilials,
  setLanguage,
  setUsersData,
  setSocket,
  addNewMessageToContractChat,
  deleteContractChatMessage,
  editContractMessage,
  setBiometricEnabled,
  setModal,
  addPersonalConsultantChatMessage,
  editPersonalConsultantMessage,
  setContractUnreadMessages,
  setPersonalConsultantChat,
  addUnreadContractMessage,
  setAccountantUnreadChatMessages,
} from '../store/commonReducer';
import reactNativeService from './reactNativeService';
import { addToast } from '../store/toastReducer';
import { translation } from 'common_constants/translation';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { useHistory } from 'react-router-dom';

const CheckClientInfo = (dispatch) => {
  request(
    '/clients/getClientInfo',
    null,
    (req) => {
      const { data } = req;
      if (!data) return;
      dispatch(setClientInfo(data));
      dispatch(setContractUnreadMessages(data?.contractUnreadMessages));
      dispatch(setAccountantUnreadChatMessages(data?.accountantChatsUnreadMessages));
      dispatch(setAppPrepared(true));
    },
    error,
  );

  request('/topFilials/getForClient', {}, ({ topFilialsRating }) => {
    dispatch(setTopFilials(topFilialsRating));
  });

  request(
    '/webinars/getWebinarsForClient',
    {},
    (req) => {
      if (req.status) {
        dispatch(setWebinars(req.data));
      }
    },
    error,
  );

  request(
    '/websiteNews/getFromTelegram',
    null,
    (req) => {
      if (req.status) {
        dispatch(setNewsFromSite(req.data));
      }
    },
    error,
  );
}; //* AuthControl and hook.js work together in App.js - Authentication / Refresh accordingly.

const CheckClientInfoOnly = (dispatch) => {
  request(
    '/clients/getClientInfo',
    null,
    (req) => {
      const { data } = req;
      if (!data) return;
      dispatch(setClientInfo(data));
      dispatch(setContractUnreadMessages(data?.contractUnreadMessages));
    },
    error,
  );
};

const useControllView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const handleResize = () => {
        dispatch(setMob(window.innerWidth < MOB_RESOLUTION));
      };

      window.screen?.orientation?.lock?.('portrait').then(
        (success) => console.log(success),
        (failure) => console.log(failure),
      );
      // window.screen?.lockOrientation?.('orientation');

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    } catch (e) {
      console.error(e);
    }
  }, []);
};

const useClientInfo = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const users = useSelector((state) => state.common.users);
  const requested = useRef(false);
  const dispatch = useDispatch();

  const getClientInfo = () => {
    if (!clientAuth._id) return;
    CheckClientInfo(dispatch);
  };

  const getFilii = () => {
    const isPartial = document.location.pathname.match(/^\/.{24}$/);

    request.get('/filii', ({ data }) => {
      dispatch(setFiliiList(data));
      isPartial && dispatch(setAppPrepared(true));
    });
  };

  const getUsers = () => {
    if (!users) {
      request('/auth/getCrmUsersForClient', {}, ({ data }) => {
        dispatch(setUsersData(data));
      });
    }
  };

  useEffect(() => {
    getFilii();

    if (!clientAuth) return;
    if (requested.current) return;
    requested.current = true;
    getClientInfo();
    getUsers();
  }, [clientAuth._id]);
};

const useLanguage = () => {
  const dispatch = useDispatch();
  let currentLang = useSelector((state) => state.common.language);

  useEffect(() => {
    const localStoredLang = localStorage.getItem('language');

    if (localStoredLang && localStoredLang !== currentLang) {
      dispatch(setLanguage(localStoredLang));
      reactNativeService.sendMessageToWebview({ language: localStoredLang });
    }
  }, [dispatch]);
};

const useSetLanguageByIpAddress = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const setLanguageByIp = async () => {
      const languageFromStorage = localStorage.getItem('language');

      if (!languageFromStorage) {
        const germanSpeakingCountries = ['DE', 'AT', 'CH', 'LU', 'LI', 'BE', 'NL'];
        const UKRAINE = 'UA';

        const countryResponse = await fetch(`https://ipinfo.io/json`);

        const { country } = await countryResponse.json();

        let language = 'en';

        if (germanSpeakingCountries.includes(country)) {
          language = 'de';
        } else if (country === UKRAINE) {
          language = 'ua';
        }

        dispatch(setLanguage(language));
        localStorage.setItem('language', language);
      }
    };

    setLanguageByIp();
  }, [dispatch]);
};

const useMessage = () => {
  const [messages, setMessages] = useState([]);
  const [nextZIndex, setNextZIndex] = useState(100);

  const showMessage = (type, content) => {
    setMessages((prevMessages) => [
      {
        id: Date.now(),
        type,
        content,
        zIndex: nextZIndex,
      },
      ...prevMessages,
    ]);
    setNextZIndex(nextZIndex + 1);
  };

  return { messages, showMessage };
};

const useAuthData = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);

  useEffect(() => {
    const data = {
      email: clientAuth?.email,
      _id: clientAuth?._id,
      ph: clientAuth?.ph,
      n: clientInfo?.n,
      clubCard: clientInfo?.clubCard,
      phoneVerified: clientAuth?.phoneVerified ?? false,
      token: localStorage.getItem('clientAccessToken'),
    };
    reactNativeService.sendMessageToWebview({ type: WEBVIEW_MESSAGE_TYPES.USER_AUTH_DATA, data: data });
  }, [clientAuth?._id, clientAuth?.phoneVerified, clientInfo?._id]);
};

const useConnectSocket = () => {
  const dispatch = useDispatch();
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const socket = useSelector((state) => state.common?.socket);

  const connectSocket = (userToken) => {
    if (window?.ReactNativeWebView) return;
    const socket = io(process.env.REACT_APP_API, {
      query: {
        token: userToken,
        tokenType: USER_TOKEN_TYPE.client,
      },
    });

    return socket;
  };

  useEffect(() => {
    if (window?.ReactNativeWebView) return;
    const token = localStorage.getItem('clientAccessToken') ?? clientAuth?.token;

    if (token) {
      const currentSocket = connectSocket(token);

      dispatch(setSocket(currentSocket));

      return () => currentSocket.close();
    } else {
      if (socket) {
        socket.close();
        dispatch(setSocket(null));
      }
    }
  }, [clientAuth?._id]);
};

const useListenMessages = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);

  useEffect(() => {
    if (!socket) return;

    socket.on('newMessage', ({ message, chatId, type }) => {
      if (type === CHAT_TYPES.personalConsultantChat.key) {
        dispatch(addPersonalConsultantChatMessage(message));
        dispatch(setPersonalConsultantChat({ unreadMessages: (personalConsultantChat?.unreadMessages ?? 0) + 1 }));
        return;
      }

      dispatch(addNewMessageToContractChat({ chatId: chatId, data: message }));
      dispatch(addUnreadContractMessage(chatId));
    });

    socket.on('removeMessage', ({ chatId, messageId }) => {
      dispatch(deleteContractChatMessage({ chatId, messageId }));
    });

    socket.on('editMessage', ({ chatId, messageId, chatType, updatedMessage }) => {
      if (chatType === CHAT_TYPES.personalConsultantChat.key) {
        dispatch(editPersonalConsultantMessage({ messageId, updatedMessage }));
      } else {
        dispatch(editContractMessage({ chatId, messageId, updatedMessage }));
      }
    });

    return () => {
      socket?.off('newMessage');
      socket?.off('removeMessage');
      socket?.off('editMessage');
    };
  }, [socket]);
};
const useToaster = () => {
  const dispatch = useDispatch();
  const createToast = (toast) => {
    dispatch(
      addToast({
        id: Date.now(),
        ...toast,
      }),
    );
  };
  const toast = {
    success: (title) => createToast({ title: title, variant: 'success' }),
    error: (title) => createToast({ title: title, variant: 'error' }),
    custom: (data) => createToast(data),
  };

  return toast;
};

const useListenWebviewMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window?.ReactNativeWebView) return;

    const messageListener = (event) => {
      try {
        const receivedData = JSON.parse(event?.data);

        if (receivedData?.type === WEBVIEW_MESSAGE_TYPES.BIOMETRIC_INITIAL) {
          dispatch(setBiometricEnabled(receivedData.data.value));
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('message', messageListener);
    document.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
      document.removeEventListener('message', messageListener);
    };
  }, []);
};

const useCheckForGift = () => {
  const clubCard = useSelector((state) => state.common?.clientInfo?.clubCard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clubCard?.isGift && !clubCard?.giftSeen && new Date(clubCard?.expiredAt) > new Date()) {
      dispatch(setModal({ name: MODALS.CLUB_CARD_GIFT }));
      dispatch(setConfetti({ visible: true }));
    }
  }, [clubCard]);
};

const usePayContractDebt = () => {
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch();
  const history = useHistory();
  const isProcessingRef = useRef(false);

  const payContractDebt = async ({ contract, clientInfo, lang }) => {
    if (isProcessingRef.current) return;
    isProcessingRef.current = true;

    const body = {
      clientId: clientInfo?._id,
      amount: Math.abs(contract?.remainingMoney),
      contractIndex: contract?.contractIndex,
      contractId: contract?.contractId,
      fil: contract?.f,
      currency: clientInfo.currency,
      payFullContractDebt: true,
    };
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          success(translation.payContractDebt[lang]);
          setStatus('success');
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              history.push(CLIENT_ROUTES.CLIENT_HOME.route);
              success(translation.payContractDebt[lang]);
              setStatus('success');
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
              setStatus('error');
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };

    const onError = (_, __, axiosError) => {
      const errorMessage = axiosError?.error || translation.addInsuranceError[lang];
      error(errorMessage);
      setStatus('error');
    };

    const onSuccessPayFromBalance = () => {
      success(translation.payContractDebt[lang]);
      CheckClientInfo(dispatch);
      setStatus('success');
    };
    const onErrorPayFromBalance = () => {
      error('', translation.addInsuranceError[lang]);
      setStatus('error');
    };

    setStatus('pending');
    try {
      if (clientInfo.clientBalance >= Math.abs(contract.remainingMoney)) {
        await request('/payment/payDebtContractEAdvokat', body, onSuccessPayFromBalance, onErrorPayFromBalance);
      } else {
        await request('/payment/mono/payContractDebt/link', body, onSuccess, onError);
      }
    } finally {
      isProcessingRef.current = false;
    }
  };

  return {
    status,
    payContractDebt,
    isSuccess: status === 'success',
    isError: status === 'error',
    isPending: status === 'pending',
  };
};

const useCheckFirstLoginBonus = () => {
  const clientInfo = useSelector((state) => state.common?.clientInfo);
  const noFirstBonus = clientInfo?.firstBonus === false;
  const appPrepared = useSelector((state) => state.common?.appPrepared);

  const dispatch = useDispatch();

  useEffect(() => {
    if (appPrepared && noFirstBonus) {
      dispatch(setModal({ name: MODALS.FIRST_LOGIN_BONUS }));
      dispatch(setConfetti({ visible: true }));
    }
  }, [noFirstBonus, appPrepared]);
};

const useCheckHotlineBonus = () => {
  const bonus = useSelector((state) => state.common?.clientInfo?.hotlineBonus);
  const appPrepared = useSelector((state) => state.common?.appPrepared);

  const dispatch = useDispatch();

  useEffect(() => {
    if (appPrepared && bonus && typeof bonus !== 'string') {
      dispatch(setModal({ name: MODALS.FIRST_HOTLINE_BONUS }));
      dispatch(setConfetti({ visible: true }));
    }
  }, [bonus, appPrepared]);
};

const useSetPageTitle = () => {
  const lang = useSelector((state) => state.common.language);

  useEffect(() => {
    const pathname = window.location.pathname;

    const route = Object.values(CLIENT_ROUTES).find((page) => page?.route === pathname);

    if (route && route.title) {
      document.title = route?.title[lang];
    } else {
      return;
    }
  }, [window.location.pathname, lang]);
};

export {
  useControllView,
  useClientInfo,
  CheckClientInfo,
  CheckClientInfoOnly,
  useLanguage,
  useMessage,
  useAuthData,
  useConnectSocket,
  useListenMessages,
  useToaster,
  useListenWebviewMessages,
  useCheckForGift,
  useSetLanguageByIpAddress,
  usePayContractDebt,
  useCheckFirstLoginBonus,
  useCheckHotlineBonus,
  useSetPageTitle,
};
